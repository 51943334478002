<template>
  <div class="pb-4">
    <p class="title is-4 has-text-dark">Thank you!</p>
    <p class="subtitle is-6 has-text-primary">
      <span>
        Our dedicated support team has received your request and will be
        investigating it shortly. We aim to get back to you by email within 24
        hours.
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'ContactFormSubmitted'
}
</script>
